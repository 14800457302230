import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/authservice/AuthService';
import { Store } from '@ngrx/store';
import { AppStateForModule } from './AppStateForModule';
import { FetchAllLocations } from './locations-ngrx/Location.actions';

@Component({
  selector: 'tim-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(public authService: AuthService, private store: Store<AppStateForModule>) {}

  ngOnInit(): void {
    setTimeout(() => {
      if (this.authService.loggedIn$) {
        this.store.dispatch(new FetchAllLocations());
      }
    }, 1500);
  }
}
