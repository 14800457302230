import { Action } from '@ngrx/store';
import { PaginatedAPIResponse } from '../../../models/PaginatedAPIResponse';
import { FramePresenter } from '../../../models/FramesPresenter';
import { MapLocationCriterion } from '../../../models/FrameFilterPresenter';

export enum MapActionsEnum {
  GET_FRAMES_FOR_MAP = '[Home Module] Get Frames for map',
  MAP_FRAMES_FETCH_SUCCESS = '[Home Module] Frames for map fetch success',
  CLEAR_FRAMES_FOR_MAP = '[Home Module] Clear Frames for map',
  SET_MAP_SHAPES = '[Home Module] Set Map Shapes',
  SET_MAP_SHAPES_SUCCESS = '[Home Module] Set Map Shapes Success',
}

export class GetFramesForMap implements Action {
  public readonly type = MapActionsEnum.GET_FRAMES_FOR_MAP;
  constructor(public frameIds: string[], public pageSize: number) {}
}

export class ClearFramesForMap implements Action {
  public readonly type = MapActionsEnum.CLEAR_FRAMES_FOR_MAP;
}

export class MapFramesFetchSuccess implements Action {
  public readonly type = MapActionsEnum.MAP_FRAMES_FETCH_SUCCESS;
  constructor(public response: PaginatedAPIResponse<FramePresenter>) {}
}

export class SetMapShapes implements Action {
  public readonly type = MapActionsEnum.SET_MAP_SHAPES;
  constructor(public shapes: MapLocationCriterion) {}
}

export class SetMapShapesSuccess implements Action {
  public readonly type = MapActionsEnum.SET_MAP_SHAPES_SUCCESS;
  constructor(public shapes: MapLocationCriterion) {}
}

export type MapsActions = GetFramesForMap | MapFramesFetchSuccess | ClearFramesForMap | SetMapShapes;
