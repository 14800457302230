import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ErrorDialogComponent } from './error-dialog.component';
import { CommonModule } from '@angular/common';
import { ErrorDialogService } from './error-dialog.service';

@NgModule({
  imports: [MatDialogModule, CommonModule],
  declarations: [ErrorDialogComponent],
  exports: [ErrorDialogComponent],
  providers: [ErrorDialogService],
})
export class ErrorDialogModule {}
