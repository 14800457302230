import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'tim-route-not-found',
  templateUrl: './route-not-found.component.html',
  styleUrls: ['./route-not-found.component.scss'],
})
export class RouteNotFoundComponent implements OnInit {
  message = 'This page does not exist';
  constructor(private router: Router) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.message = this.router.getCurrentNavigation().extras.state.message;
    }
  }

  ngOnInit() {
    setTimeout(() => this.router.navigate(['home']), 5000);
  }
}
