import { Component } from '@angular/core';
import { AuthService } from '../services/authservice/AuthService';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ChangeToListView } from '../home-page/avails-view/frame-filter/actions/ChangeViewActions';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ClearFramesForMap } from '../home-page/map-view/actions/maps.actions';

@Component({
  selector: 'tim-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class TimNavBarComponent {
  constructor(public authService: AuthService, public router: Router, private store: Store, private spinner: NgxUiLoaderService) {}

  logout$() {
    this.authService.logout();
  }

  public inUploadFrameRoute(): boolean {
    return this.router.url === '/upload-frame';
  }

  public inUploadFramesPricingRulesRoute(): boolean {
    return this.router.url === '/upload-frame-pricing-rules';
  }

  public inCreateFrameRoute(): boolean {
    return this.router.url === '/create-frame';
  }

  public inUploadAvailsRoute(): boolean {
    return this.router.url === '/frames-avails';
  }

  public inProductsRoute(): boolean {
    return this.router.url === '/products';
  }

  navigateToListView() {
    this.spinner.start();
    this.store.dispatch(new ClearFramesForMap());
    this.store.dispatch(new ChangeToListView());
    this.spinner.stop();
  }

  inCreateProductsRoute() {
    return this.router.url === '/products/create';
  }

  inCreateOrganizationRoute() {
    return this.router.url === '/organization/create';
  }

  inOrganizationRoute() {
    return this.router.url === '/organization';
  }

  inAccountRoute() {
    return this.router.url === '/accounts';
  }

  inCreateAccountRoute() {
    return this.router.url === '/accounts/create';
  }

  inOrderReportRoute() {
    return this.router.url === '/orders/download-report';
  }
}
