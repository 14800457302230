import { LocationActions, LocationsActionEnum } from './Location.actions';
import { getLocations } from '../utils/LocationsUtils';
import { LocationFilter, LocationForLocale } from '../models/LocationFilter';

const initialStateForAllLocations: LocationFilter[] = [];

export function LocationsReducer(state = initialStateForAllLocations, action: LocationActions) {
  switch (action.type) {
    case LocationsActionEnum.LOCATION_FETCH_SUCCESS: {
      action.payload.forEach((loc) => (loc.distinctLocation = loc.location + '_' + loc.locationType));
      return action.payload;
    }

    default:
      return state;
  }
}

export const initialStateForLocale: LocationForLocale = {
  state: [],
  fetched: false,
  city: [],
  cbsaName: [],
  county: [],
  postcode: [],
  tvRegion: [],
};

export function LocationReducerForLocale(state = initialStateForLocale, action: LocationActions) {
  switch (action.type) {
    case LocationsActionEnum.FETCH_LOCATIONS_FOR_LOCALE_SUCCESS: {
      const mutatedState = getLocations(state, action.payload, action.locationLevel, action.locale);
      mutatedState.fetched = true;
      return mutatedState;
    }

    default:
      return { ...state };
  }
}
