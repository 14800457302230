import { Injectable } from '@angular/core';
import { HttpService } from './http-service/http.service';
import { environment } from '../../environments/environment';
import { PaginatedAPIResponse } from '../models/PaginatedAPIResponse';
import { LocationFilter } from '../models/LocationFilter';
import { Network } from '../models/Network';

@Injectable()
export class FilterService {
  readonly locationFilterUrl = environment.endPoints.location;
  readonly _environmentFilterEndpoint: string = environment.endPoints.filters.environment;
  readonly _formatFilterEndpoint: string = environment.endPoints.filters.format;
  readonly _networkFilterEndpoint: string = environment.endPoints.filters.network;
  readonly _timezoneEndpoint: string = environment.endPoints.timezone;
  constructor(private _httpService: HttpService) {}

  getLocationFilter() {
    return this._httpService.get<LocationFilter[]>(environment.apiUrl, this.locationFilterUrl + '/all');
  }

  getEnvironmentFilters() {
    return this._httpService.post<PaginatedAPIResponse<string>>(environment.apiUrl, this._environmentFilterEndpoint);
  }

  getLocationByLevel(locationLevel: string) {
    return this._httpService.get<LocationFilter[]>(environment.apiUrl, this.locationFilterUrl + '?type=' + locationLevel);
  }

  getFormatFilters() {
    return this._httpService.get<PaginatedAPIResponse<string>>(environment.apiUrl, this._formatFilterEndpoint);
  }

  getNetworkFilters(mediaOwnerName: string) {
    return this._httpService.get<PaginatedAPIResponse<Network>>(environment.apiUrl, this._networkFilterEndpoint + mediaOwnerName);
  }

  getTimezones() {
    return this._httpService.get<string[]>(environment.apiUrl, this._timezoneEndpoint);
  }
}
