import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeUS from '@angular/common/locales/en';
import localeGB from '@angular/common/locales/en-GB';
import localeSpain from '@angular/common/locales/es';
import localeMalaysia from '@angular/common/locales/ms';
import { TranslateService } from '@ngx-translate/core';
import { MediaOwnerConfig } from '../models/MediaOwnerConfig';

@Injectable({
  providedIn: 'root',
})
export class ClientConfigService {
  set clientConfig(value: MediaOwnerConfig) {
    this._clientConfig = value;
    this.registerLocale();
    this.translate.setDefaultLang(value.locale);
  }

  private _clientConfig: MediaOwnerConfig;

  get clientConfig(): MediaOwnerConfig {
    return this._clientConfig;
  }

  constructor(private translate: TranslateService) {}

  private registerLocale() {
    switch (this._clientConfig.locale) {
      case 'en': {
        registerLocaleData(localeUS);
        break;
      }
      case 'es': {
        registerLocaleData(localeSpain);
        break;
      }
      case 'en_GB': {
        registerLocaleData(localeGB);
        break;
      }
      case 'ms': {
        registerLocaleData(localeMalaysia);
        break;
      }
    }
  }
}
