<mat-toolbar>
  <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex>
    <a [routerLink]="['/home']" (click)="navigateToListView()">
      <h2 class="medium-font header">Trading & Inventory Management</h2>
    </a>
    <span fxFlex="1 1 auto"></span>

    <button mat-button class="drowdown-placeholder" [matMenuTriggerFor]="tradingNavigation">
      Trading
      <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
    </button>

    <button mat-button class="drowdown-placeholder" [matMenuTriggerFor]="inventoryNavigation">
      Inventory
      <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
    </button>

    <mat-menu #inventoryNavigation="matMenu" class="navigation-menu-header">
      <button mat-menu-item *ngIf="!inUploadFramesPricingRulesRoute()" [routerLink]="['/upload-frame-pricing-rules']">
        <span>Pricing</span>
      </button>

      <mat-divider *ngIf="!inUploadFramesPricingRulesRoute()"></mat-divider>

      <!--      <button class='menu-category' mat-menu-item disabled>-->
      <div class="menu-section-title">Frames</div>
      <!--      </button>-->

      <button *ngIf="!inUploadAvailsRoute()" [routerLink]="['/frames-avails']" mat-menu-item>
        <span>Update Frame Availability</span>
      </button>

      <button mat-menu-item [routerLink]="['/create-frame']" *ngIf="!inCreateFrameRoute()">
        <span>Create New</span>
      </button>

      <button mat-menu-item *ngIf="!inUploadFrameRoute()" [routerLink]="['/upload-frame']">
        <span>Import</span>
      </button>
    </mat-menu>

    <mat-menu #tradingNavigation="matMenu" class="navigation-menu-header">
      <div class="menu-section-title">Products</div>

      <button data-testid="create-product-navigation" mat-menu-item *ngIf="!inCreateProductsRoute()" [routerLink]="['/products/create']">
        <span>Create New</span>
      </button>

      <button mat-menu-item *ngIf="!inProductsRoute()" [routerLink]="['/products']">
        <span>View All</span>
      </button>

      <mat-divider></mat-divider>
      <!-- <div> -->
      <div class="menu-section-title">Organizations</div>

      <button
        id="Create-new-organization"
        *ngIf="!inCreateOrganizationRoute() && authService.isAdmin"
        mat-menu-item
        [routerLink]="['/organization/create']"
      >
        <span>Create New</span>
      </button>

      <button *ngIf="!inOrganizationRoute()" class="org-section-all" mat-menu-item [routerLink]="['/organization']">
        <span>View All</span>
      </button>

      <mat-divider></mat-divider>
      <!-- <div> -->
      <div class="menu-section-title">Accounts</div>

      <button *ngIf="!inCreateAccountRoute()" mat-menu-item [routerLink]="['/accounts/create']">
        <span>Create New</span>
      </button>

      <button *ngIf="!inAccountRoute()" mat-menu-item [routerLink]="['/accounts']">
        <span>View All</span>
      </button>

      <mat-divider></mat-divider>
      <!-- </div> -->
      <div class="menu-section-title">Orders</div>

      <button data-testid="report-navigation" *ngIf="!inOrderReportRoute()" mat-menu-item [routerLink]="['/orders/download-report']">
        <span>Report</span>
      </button>
    </mat-menu>

    <div fxLayoutAlign="center start">
      <button mat-button [matMenuTriggerFor]="menu" class="user-button">
        <div fxLayout="row wrap" fxLayoutGap="5px" fxLayoutAlign="center center">
          <div fxLayout="column wrap">
            <img [src]="authService.userProfile.picture" />
          </div>
          <div fxLayout="column wrap">
            <span>{{ authService.userProfile.nickname }}</span>
            <span class="tenant-information">{{ authService.displayName }}</span>
          </div>
        </div>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="logout$()">Logout</button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>
