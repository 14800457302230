import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { RouteNotFoundComponent } from './route-not-found/route-not-found.component';
import { AuthenticationGuard } from './guards/AuthenticationGuard';
import { LoginComponent } from './LoginComponent/Login.component';
import { ClientConfigResolver } from './resolver/ClientConfigResolver';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./home-page/home.module').then((m) => m.HomePageModule),
    canActivate: [AuthenticationGuard],
    resolve: { clientConfig: ClientConfigResolver },
  },
  {
    path: 'create-frame',
    loadChildren: () => import('./create-frame-page/create-frame.module').then((m) => m.CreateFrameModule),
    canActivate: [AuthenticationGuard],
    resolve: { clientConfig: ClientConfigResolver },
  },
  {
    path: 'upload-frame',
    loadChildren: () => import('./upload-frame-page/upload-frame.module').then((m) => m.UploadFrameModule),
    canActivate: [AuthenticationGuard],
    resolve: { clientConfig: ClientConfigResolver },
  },
  {
    path: 'upload-frame-pricing-rules',
    loadChildren: () =>
      import('./upload-frame-pricing-rules-page/upload-frame-pricing-rules.module').then((m) => m.UploadFramePricingRulesModule),
    canActivate: [AuthenticationGuard],
    resolve: { clientConfig: ClientConfigResolver },
  },
  {
    path: 'frames',
    loadChildren: () => import('./edit-frame-page/edit-frame.module').then((m) => m.EditFrameModule),
    canActivate: [AuthenticationGuard],
    resolve: { clientConfig: ClientConfigResolver },
  },
  {
    path: 'frames-avails',
    loadChildren: () => import('./frames-availability/frames-availability.module').then((m) => m.FramesAvailabilityModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'products',
    loadChildren: () => import('./trading/products/products.module').then((m) => m.ProductsModule),
    canActivate: [AuthenticationGuard],
    resolve: { clientConfig: ClientConfigResolver },
  },
  {
    path: 'organization',
    loadChildren: () => import('./trading/organization/organization.module').then((m) => m.OrganizationModule),
    canActivate: [AuthenticationGuard],
    resolve: { clientConfig: ClientConfigResolver },
  },
  {
    path: 'accounts',
    loadChildren: () => import('./trading/accounts/accounts.module').then((m) => m.AccountsModule),
    canActivate: [AuthenticationGuard],
    resolve: { clientConfig: ClientConfigResolver },
  },
  {
    path: 'orders',
    loadChildren: () => import('./trading/orders/orders.module').then((m) => m.OrdersModule),
    canActivate: [AuthenticationGuard],
    resolve: { clientConfig: ClientConfigResolver },
  },
  { path: 'login', component: LoginComponent },
  {
    path: '**',
    component: RouteNotFoundComponent,
    canActivate: [AuthenticationGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
