import { Component } from '@angular/core';
import { AuthService } from '../services/authservice/AuthService';
import { Router } from '@angular/router';

@Component({
  selector: 'tim-login-component',
  templateUrl: './Login.component.html',
})
export class LoginComponent {
  constructor(private authService: AuthService, private router: Router) {
    setTimeout(() => {
      if (!this.authService.loggedIn$) {
        this.authService.login();
      } else {
        this.router.navigate(['/home']);
      }
    }, 1500);
  }
}
