import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, distinct, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppStateForModule } from '../AppStateForModule';
import { FilterService } from '../services/filter.service';
import { CustomNotificationService } from '../services/custom-notification-service/custom-notification.service';
import {
  AllLocationsFetchSuccess,
  FetchAllLocations,
  FetchLocationForLocaleSuccess,
  FetchLocationsForLocale,
  LocationsActionEnum,
} from './Location.actions';

@Injectable()
export class LocationsEffect {
  constructor(
    private _action$: Actions,
    private _store: Store<AppStateForModule>,
    private _filtersFetchService: FilterService,
    private notificationService: CustomNotificationService
  ) {}

  getLocationFilters = createEffect(() =>
    this._action$.pipe(
      ofType<FetchAllLocations>(LocationsActionEnum.FETCH_LOCATION_FILTER),
      mergeMap(() =>
        this._filtersFetchService.getLocationFilter().pipe(
          distinct(),
          map((distinctLocation) => new AllLocationsFetchSuccess(distinctLocation)),
          catchError(async (err) => {
            this.notificationService.errorNotification(err.error.msg);
            return undefined;
          })
        )
      )
    )
  );

  getLocationForLocale = createEffect(() =>
    this._action$.pipe(
      ofType<FetchLocationsForLocale>(LocationsActionEnum.FETCH_LOCATIONS_FOR_LOCALE),
      mergeMap((input) =>
        this._filtersFetchService.getLocationByLevel(input.locationLevel).pipe(
          distinct(),
          map((distinctLocations) => new FetchLocationForLocaleSuccess(distinctLocations, input.locationLevel, input.locale)),
          catchError(async (err) => {
            this.notificationService.errorNotification(err.error.msg);
            return undefined;
          })
        )
      )
    )
  );
}
