import { Injectable, SkipSelf } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/authservice/AuthService';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  private authorization_header: string;
  private uidLength = 16;

  constructor(@SkipSelf() private authService: AuthService) {
    this.authorization_header = 'Authorization';
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.transform(req)).pipe(
      tap(
        () => {},
        (error: HttpErrorResponse) => this.handleError(error)
      )
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 401) {
      this.authService.logout();
    }
  }

  private transform(request: HttpRequest<any>) {
    const spanId = hex(this.uidLength);
    const traceId = hex(this.uidLength);
    return request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + this.authService.authToken,
        'X-B3-Spanid': spanId,
        'X-B3-Traceid': traceId,
      },
    });
  }
}

function hex(len) {
  const letters = '0123456789abcdef';
  let hexString = '';
  for (let i = 0; i < len; i++) {
    hexString += letters[Math.floor(Math.random() * 16)];
  }
  return hexString;
}
