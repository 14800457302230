import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpService } from '../services/http-service/http.service';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { ClientConfigService } from '../services/ClientConfigService';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ClientConfigResolver {
  constructor(private httpService: HttpService, private clientConfigService: ClientConfigService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<{ locale: string; 'poi.enabled': boolean; currency: string }>
    | Promise<{ locale: string; 'poi.enabled': boolean; currency: string }>
    | { locale: string; 'poi.enabled': boolean; currency: string } {
    if (!this.clientConfigService.clientConfig) {
      return this.httpService
        .get<{ locale: string; 'poi.enabled': boolean; currency: string }>(environment.apiUrl, environment.endPoints.locale)
        .pipe(tap((config) => (this.clientConfigService.clientConfig = config)));
    } else {
      return this.clientConfigService.clientConfig;
    }
  }
}
