export enum LocationLevel {
  country = 'COUNTRY',
  level1 = 'LEVEL_1',
  level2 = 'LEVEL_2',
  level3 = 'LEVEL_3',
  level4 = 'LEVEL_4',
  level5 = 'LEVEL_5',
  level6 = 'LEVEL_6',
}

export const geographyCriterionMappingES = new Map<string, string>([
  [LocationLevel.level1, 'state'],
  [LocationLevel.level2, 'city'],
  [LocationLevel.level3, 'county'],
  [LocationLevel.level4, 'cbsaName'],
  [LocationLevel.level5, 'postalCodes'],
]);

export const geographyCriterionMappingMS = new Map<string, string>([[LocationLevel.level1, 'city']]);

export const geographyCriterionMappingUS = new Map<string, string>([
  [LocationLevel.level1, 'state'],
  [LocationLevel.level2, 'county'],
  [LocationLevel.level3, 'city'],
  [LocationLevel.level4, 'cbsaName'],
  [LocationLevel.level5, 'tvRegion'],
  [LocationLevel.level6, 'postalCodes'],
]);

export const geographyCriterionMappingENGB = new Map<string, string>([
  [LocationLevel.level1, 'tvRegion'],
  [LocationLevel.level2, 'conurbations'],
  [LocationLevel.level3, 'name'],
  [LocationLevel.level4, 'postalCodes'],
]);

export const geographyFrameMappingES = new Map<string, string>([
  [LocationLevel.level1, 'state'],
  [LocationLevel.level2, 'city'],
  [LocationLevel.level3, 'county'],
  [LocationLevel.level4, 'cbsaName'],
  [LocationLevel.level5, 'postcode'],
]);

export const geographyFrameMappingENGB = new Map<string, string>([
  [LocationLevel.level1, 'tvRegion'],
  [LocationLevel.level2, 'conurbation'],
  [LocationLevel.level3, 'town'],
  [LocationLevel.level4, 'postcode'],
]);

export const geographyFrameMappingMS = new Map<string, string>([[LocationLevel.level1, 'city']]);

export const geographyFrameMappingUS = new Map<string, string>([
  [LocationLevel.level1, 'state'],
  [LocationLevel.level2, 'county'],
  [LocationLevel.level3, 'city'],
  [LocationLevel.level4, 'cbsaName'],
  [LocationLevel.level5, 'tvRegion'],
  [LocationLevel.level6, 'postcode'],
]);

export const geographyCriterionMapping = new Map<string, Map<string, string>>([
  ['es', geographyCriterionMappingES],
  ['en', geographyCriterionMappingUS],
  ['ms', geographyCriterionMappingMS],
  ['en_GB', geographyCriterionMappingENGB],
]);

export const geographyFrameMapping = new Map<string, Map<string, string>>([
  ['es', geographyFrameMappingES],
  ['en', geographyFrameMappingUS],
  ['ms', geographyFrameMappingMS],
  ['en_GB', geographyFrameMappingENGB],
]);

export interface LocationFilter {
  locationType: string;
  location: string;
  distinctLocation: string;
  parent: string;
  ancestors: string[];
  level: LocationLevel;
}

export interface LocationForLocale {
  state: [];
  county: [];
  city: [];
  cbsaName: [];
  tvRegion: [];
  postcode: [];
  fetched: boolean;
}
