import { Action } from '@ngrx/store';

export enum ViewChangeEnum {
  CHANGE_TO_MAP_VIEW = '[Home Module] Change to Map View',
  CHANGE_TO_LIST_VIEW = '[Home Module] Change to List View',
}

export class ChangeToMapView implements Action {
  public readonly type = ViewChangeEnum.CHANGE_TO_MAP_VIEW;
}

export class ChangeToListView implements Action {
  public readonly type = ViewChangeEnum.CHANGE_TO_LIST_VIEW;
}

export type ChangeViewActions = ChangeToMapView | ChangeToListView;
