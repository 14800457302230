import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Auth0Lock from 'auth0-lock';
import { environment } from '../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ErrorDialogService } from '../../custom-components/error-dialog-component/error-dialog.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  helper = new JwtHelperService();
  private _authToken: string;
  private _userProfile: any;

  auth0Options = {
    theme: {
      logo: '/assets/logo.jpg',
      primaryColor: '#4586e8',
    },
    auth: {
      redirectUrl: environment.auth0.callbackUrl,
      responseType: 'token id_token',
      audience: environment.auth0.audience,
      params: {
        scope: 'openid profile email offline_access',
      },
    },
    autoclose: true,
    oidcConformant: true,
    allowAutocomplete: true,
    allowShowPassword: true,
    avatar: true,
    autofocus: true,
    allowForgotPassword: true,
    closable: false,
    rememberLastLogin: false,
    allowSignUp: false,
  };

  lock = new Auth0Lock(environment.auth0.clientId, environment.auth0.domain, this.auth0Options);

  constructor(private router: Router, private errorDialogService: ErrorDialogService) {
    // Setup callbacks for Auth0
    // Auth event and error event

    this.lock.on('authenticated', (authResult: any) => {
      const userProfile = {
        nickname: authResult.idTokenPayload.nickname,
        picture: authResult.idTokenPayload.picture,
      };
      this._authToken = authResult.accessToken;
      this._userProfile = userProfile;

      localStorage.setItem('token', this._authToken);
      localStorage.setItem('profile', JSON.stringify(this._userProfile));

      this.router.navigate(['/home']).then(() => {
        this.lock.hide();
      });
    });

    this.lock.on('authorization_error', (error) => {});

    /*
      Everytime on application startup / AuthService init,
      read the LocalStorage and populate tokens locally
      so that, we don't need to read localStorage.get() in everyplace :)
     */

    if (this.isAuthenticated()) {
      this._authToken = localStorage.getItem('token');
      this._userProfile = JSON.parse(localStorage.getItem('profile'));
    }
  }

  login() {
    this.lock.show();
  }

  logout() {
    localStorage.removeItem('profile');
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }

  private isAuthenticated() {
    try {
      return !this.helper.isTokenExpired(localStorage.getItem('token'));
    } catch (error) {
      const options = {
        title: 'JWT token Invalid!',
        message: 'The token you are using is invalid, please login again',
      };

      setTimeout(() => this.errorDialogService.open(options), 1000);

      return false;
    }
  }

  get tenantName() {
    return this.helper.decodeToken(localStorage.getItem('token'))[environment.auth0.appUrl].tenant;
  }

  get displayName() {
    let tenantInfo = this.helper.decodeToken(localStorage.getItem('token'))[environment.auth0.appUrl];
    return tenantInfo.displayName || this.tenantName;
  }

  get loggedIn$() {
    return this.isAuthenticated();
  }

  get userProfile(): any {
    return this._userProfile;
  }

  get authToken(): any {
    return localStorage.getItem('token');
  }

  get isAdmin(): boolean {
    let authorities: string[] = this.helper.decodeToken(localStorage.getItem('token'))[environment.auth0.appUrl].authorities;
    return authorities.includes('ROLE_TIM_MANAGEMENT_ADMIN');
  }
}
