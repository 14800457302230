import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  headers = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json',
  });

  constructor(private http: HttpClient) {}

  get<T>(apiUrl, endpoint: string): Observable<T> {
    return this.http.get<T>(apiUrl + endpoint, { headers: this.headers });
  }

  getWithParams<T>(apiUrl, endpoint: string, params: HttpParams): Observable<T> {
    return this.http.get<T>(apiUrl + endpoint, { headers: this.headers, params });
  }

  delete<T>(endpoint: string, params: HttpParams): Observable<T> {
    return this.http.delete<T>(environment.apiUrl + endpoint, { headers: this.headers, params });
  }

  request<T>(method: string, endpoint: string, payload: object): Observable<T> {
    return this.http.request<T>(method, environment.apiUrl + endpoint, {
      body: payload,
      headers: this.headers,
      responseType: 'json',
    });
  }

  downloadTemplateFile(endpoint: string, apiUrl?: string) {
    const header = new HttpHeaders({});
    const baseUrl = apiUrl ? apiUrl : environment.apiUrl;

    return this.http.get(baseUrl + endpoint, { headers: header, responseType: 'blob' });
  }

  downloadZipFile(endpoint: string, payload: object = {}) {
    const header = new HttpHeaders({});
    return this.http.post(environment.apiUrl + endpoint, payload, { headers: header, responseType: 'blob' });
  }

  post<T>(apiUrl, endpoint: string, payload: object = {}): Observable<T> {
    return this.http.post<T>(apiUrl + endpoint, payload, { headers: this.headers });
  }

  postWithHeaders<T>(apiUrl, endpoint: string, headers: HttpHeaders, payload: object = {}): Observable<T> {
    return this.http.post<T>(apiUrl + endpoint, payload, { headers: headers });
  }

  postWithOptions(apiUrl, endpoint: string, body, options): Observable<any> {
    options.body = body;
    return this.http.request('POST', apiUrl + endpoint, options);
  }

  patch<T>(apiUrl, endpoint: string, payload: object = {}): Observable<T> {
    return this.http.patch<T>(apiUrl + endpoint, payload, { headers: this.headers });
  }

  put<T>(apiUrl, endpoint: string, payload: object = {}): Observable<T> {
    return this.http.put<T>(apiUrl + endpoint, payload, { headers: this.headers });
  }

  fileUpload(endpoint: string, fileToUpload: File, jobType: string) {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('jobType', jobType);

    const multiPartHeaders = new HttpHeaders({
      Accept: 'application/json',
    });

    return this.http.post(environment.apiUrl + endpoint, formData, { headers: multiPartHeaders });
  }

  photoUpload(endpoint: string, filesToUpload: File[], requestParamName: string) {
    const formData: FormData = new FormData();
    for (const file of filesToUpload) {
      formData.append(requestParamName, file, file.name);
    }

    const multiPartHeaders = new HttpHeaders({
      Accept: '*/*',
    });

    return this.http.post(environment.apiUrl + endpoint, formData, { headers: multiPartHeaders });
  }

  downloadFile(endpoint: string) {
    const header = new HttpHeaders({
      Accept: 'text/csv',
      'Content-Type': 'text/csv',
    });

    return this.http.get(environment.apiUrl + endpoint, { headers: header, responseType: 'text' as any });
  }

  getDownloadUrl(apiUrl, endpoint: string) {
    return this.http.get<string>(apiUrl + endpoint, { responseType: 'text' as any });
  }
}
