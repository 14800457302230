import { Action } from '@ngrx/store';
import { LocationFilter } from '../models/LocationFilter';

export enum LocationsActionEnum {
  FETCH_LOCATION_FILTER = '[APP START] Fetch location Filter',
  LOCATION_FETCH_SUCCESS = '[APP START] Location fetch success',
  FETCH_LOCATIONS_FOR_LOCALE = '[APP START] Fetch Locations for Locale',
  FETCH_LOCATIONS_FOR_LOCALE_SUCCESS = '[APP START] Fetched Locations for locale, Success',
}

export class FetchAllLocations implements Action {
  public readonly type = LocationsActionEnum.FETCH_LOCATION_FILTER;
}

export class AllLocationsFetchSuccess implements Action {
  public readonly type = LocationsActionEnum.LOCATION_FETCH_SUCCESS;

  constructor(public payload: LocationFilter[]) {}
}

export class FetchLocationsForLocale implements Action {
  public readonly type = LocationsActionEnum.FETCH_LOCATIONS_FOR_LOCALE;

  public constructor(public locationLevel: string, public locale: string) {}
}

export class FetchLocationForLocaleSuccess implements Action {
  public readonly type = LocationsActionEnum.FETCH_LOCATIONS_FOR_LOCALE_SUCCESS;

  constructor(public payload: LocationFilter[], public locationLevel: string, public locale: string) {}
}

export type LocationActions = FetchAllLocations | AllLocationsFetchSuccess | FetchLocationsForLocale | FetchLocationForLocaleSuccess;
