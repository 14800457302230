import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, TimeoutError } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ServerMaintenanceInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        () => {},
        (error) => {
          this.handleError(error);
        }
      )
    );
  }
  handleError(error: HttpErrorResponse) {
    if (error.status === 503 || error instanceof TimeoutError) {
      this.router.navigate(['/server_under_maintenance'], {
        state: {
          message: 'Server is under maintenance. Sorry for the inconvenience caused',
        },
      });
    }
  }
}
