import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { toastrConfig } from 'src/app/models/init-values/ToastrConfig';

@Injectable({
  providedIn: 'root',
})
export class CustomNotificationService {
  constructor(private toastr: ToastrService) {}

  successNotification(message: string, title?: string) {
    this.toastr.success(message, title === undefined ? '' : title, toastrConfig);
  }

  errorNotification(message: string) {
    this.toastr.error(message, '', toastrConfig);
  }
}
