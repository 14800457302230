import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouteNotFoundComponent } from './route-not-found/route-not-found.component';
import { TimNavBarComponent } from './tim-navbar/navbar.component';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './reducers';
import { reducer } from './locations-ngrx';
import { EffectsModule } from '@ngrx/effects';
import { NgrxFormsModule } from 'ngrx-forms';
import { AuthenticationGuard } from './guards/AuthenticationGuard';
import { LoginComponent } from './LoginComponent/Login.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { ErrorDialogModule } from './custom-components/error-dialog-component/error-dialog.module';
import { AuthenticationInterceptor } from './interceptors/AuthenticationInterceptor';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ngxUiLoaderConfig } from '../assets/config/spinner';
import { ServerMaintenanceInterceptor } from './interceptors/ServerMaintenanceInterceptor';
import { MaterialModule } from './custom-components/material-modules-with-ngrx-view-adapter';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthService } from './services/authservice/AuthService';
import { LocationsEffect } from './locations-ngrx/Locations.effect';
import { FilterService } from './services/filter.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { environment } from '../environments/environment';

import { buildSpecificsModules } from './build-specifics';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent, RouteNotFoundComponent, TimNavBarComponent, LoginComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatButtonModule,
    HttpClientModule,
    MatToolbarModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forRoot([LocationsEffect]),
    StoreModule.forFeature('location', reducer),
    NgrxFormsModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    ErrorDialogModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    MatSnackBarModule,
    MaterialModule,
    ToastrModule.forRoot(),
    ...buildSpecificsModules,
  ],
  providers: [
    AuthenticationGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
      deps: [AuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerMaintenanceInterceptor,
      multi: true,
    },
    FilterService,
    { provide: MAT_DATE_LOCALE, useValue: environment.localeId },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
